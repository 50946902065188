<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-select
          @change="typeName"
          v-model="categoriesValue"
          class="w120 mb10 mr10"
          placeholder="商品分类"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.name"
            :value="item.goodstypeId"
          >
          </el-option>
        </el-select>
        <el-input
          class="w120 mb10 mr10"
          placeholder="商品名称"
          v-model="filterName"
          clearable
        />

        <!--
        <el-select
          @change="putaway"
          v-model="shelvesValue"
          class="w120 mb10 mr10"
          placeholder="是否上架"
        >
          <el-option
            v-for="(item, index) in ['全部','是', '否']"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="distributionValue"
          @change="agency"
          class="w120 mb10 mr10"
          placeholder="是否分销"
        >
          <el-option
            v-for="(item, index) in ['是', '否']"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select> -->
        <el-button type="primary"  @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma mr10 mb10" type="primary" @click="goSetHotWord()" :disabled="!hasPermi">设置搜索热词</el-button>
        <el-button class="ma mr10 mb10" @click="isPublicAll(true)" :disabled="!hasPermi">批量上架</el-button>
        <el-button class="ma mr10 mb10" @click="isPublicAll(false)" :disabled="!hasPermi">批量下架</el-button>
        <el-button class="ma mr10 mb10" @click="delAll" :disabled="!hasPermi">批量删除</el-button>
        <el-button class="ma" type="primary" @click="getRedactOrAdd()" :disabled="!hasPermi">+添加商品</el-button>
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
      :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="goodPhoto" align="center" label="商品图片" width="150px">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.imgList"
              style="width: 100px; height: 80px;margin-bottom:-10px"
              :src="baseImgUrl+scope.row.imgList.split(',')[0]"
            ></el-image>
            <p
              v-else
              style="width: 100px; height: 80px;line-height: 80px;margin-bottom:-10px"
            >
              暂无图片
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          align="center"
          label="商品名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="typeName"
          align="center"
          width="150"
          label="分类"
          show-overflow-tooltip
        />
        <el-table-column
          prop="price"
          align="center"
          width="150"
          label="价格"
          show-overflow-tooltip
        >
        <template slot-scope="scope">
          <template v-if="!scope.row.isNegotiable">
            {{scope.row.price | formatAmount}}
          </template>
          <template v-else>
            价格面议
          </template>
        </template>
        </el-table-column>
        <el-table-column prop="stockNum"  align="center" label="总库存" />
        <el-table-column prop="dealNum"  align="center" label="总销量" />
        <el-table-column prop="dealNum"  align="center" label="公众号文章引用链接" width="200">
          <template slot-scope="scope">
            <el-button @click="copyLink('pages/prodDetail/main?goodsId='+scope.row.goodsId)" type="text" size="mini" class="tag-read" :data-clipboard-text="publicLink">
              复制链接
            </el-button>
            <!-- <el-button @click="copyLink(`pages/productDetail/detail?goodsId=${scope.row.goodsId}`)" type="text" size="mini" class="tag-read" :data-clipboard-text="publicLink">
              码客查复制链接
            </el-button> -->
            <!-- <div>名片小程序：<br />{{ `pages/prodDetail/main?goodsId=${scope.row.goodsId}`}}</div>
            <div>码客查小程序：<br />{{ `pages/productDetail/detail?goodsId=${scope.row.goodsId}`}}</div> -->
          </template>
        </el-table-column>
        <el-table-column prop="releaseTime"  align="center" label="发布时间">
          <template slot-scope="scope">
            {{ scope.row.releaseTime ? scope.row.releaseTime : scope.row.createTime }}
            </template>
        </el-table-column>
         <!-- <el-table-column prop="" align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，商品排序越靠前" placement="top-start">
              <el-input v-model="scope.row.sort"  placeholder="数值越大，商品排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <!--  <el-table-column
            prop="distributionRatioOrAmount"
            align="center"
            label="分销比例/金额"
          /> -->

        <!-- <el-table-column prop="isPublic" align="center" label="是否上架">
          <template slot-scope="scope">
            <el-button
              @click="isPublic(scope.row)"
              v-if="scope.row.isPutOn"
              type="text"
              size="mini"
              >下架</el-button
            >
            <el-button
              @click="isPublic(scope.row)"
              type="text"
              v-else
              size="mini"
              >上架</el-button
            >
          </template>
        </el-table-column> -->

        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="getRedactOrAdd(scope.row)"
              :disabled="!hasPermi"
              >编辑</el-button
            >
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              title="确认删除？">
                <el-button
                  type="danger"
                  size="mini"
                  class="ma ml10 mb10"
                  slot="reference"
                  plain
                  :disabled="!hasPermi"
                  >删除</el-button
                >
            </customPopconfirm>
            <el-button
              @click="isPublic(scope.row, scope.$index)"
              size="mini"
              v-if="scope.row.isPutOn"
              class="ma ml10 mb10"
              :disabled="!hasPermi"
              >下架</el-button
            >
            <el-button
              @click="isPublic(scope.row, scope.$index)"
              class="ma ml10 mb10"
              v-else
              size="mini"
              >上架</el-button
            >
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <el-dialog
      title="设置搜索热词"
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
     
      <el-form
          :model="wordsForm"
          ref="wordsForm"
          label-width="100px"
          class="ruleForm"
        >
          <el-form-item
              v-for="(wordsItem, index) in wordsForm.wordsList"
              :label="'热词' + (index + 1)"
              :key="wordsItem.key"
              :prop="'wordsList.' + index + '.value'"
              style="font-size：16px"
              :rules="{
                required: true, message: '搜索热词不能为空', trigger: 'blur'
              }"
            >
            <el-input v-model="wordsItem.value" style="width: 300px; font-size：12px; float: left; margin-right: 10px;"></el-input>
            <el-button style="float:left;" @click.prevent="removeWordsItem(index)" v-if="wordsForm.wordsList.length !==1 ">删除</el-button>
            <el-button style="float:left;" @click.prevent="adddWordsItem()" v-if="index === wordsForm.wordsList.length-1">添加</el-button>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button class="btncss" @click="submitwordsForm('wordsForm')">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
// @ts-ignore
import Clipboard from 'clipboard'
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec } from "@/utils";
import {baseImgUrl} from "@/utils/constant"
import {formatAmount} from '@/utils/utils'


// @ts-ignore
import {
  getGoodsInfo,
  getGoodsTypeInfo,
  delGoods,
  updateOnline,
  updateGoodsSort,
  fetchAddHotWord,
  fetchHotWordList,
} from "@/api/coupon.js";
import httpUtil from '../../../utils/httpUtil';

export default {
  name: "productList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
        type: Boolean,
        default: false,
    },
  },

  data() {
    return {
      hasPermi: true,
      userInfo: this.$store.state.loginRoot.userInfo,
      baseImgUrl:baseImgUrl,
      options: [],
      categoriesValue: "全部", //商品分类
      shelvesValue: "", //是否上架
      distributionValue: "", //是否分销
      tableData: [],
      filterName: "", //搜索\
      tableDataF: [], //筛选
      delGoods: [],
      page:1,
      loading: false, //加载中\
      total:0,
      pageNum:1,
      pageSize:10,
      publicLink: null,
      dialogVisible: false,
      wordsForm: { // 搜索热词
        wordsList:[
          {
            value: '',
            key: Date.now()
          }
        ],
      },
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
    formatAmount(amount){
       return formatAmount(amount)
     }
  },
  methods: {
    // 跳到设置搜索热词
    goSetHotWord() {
      this.openDialog()
    },
    // 批量操作
    handleSelectionChange(value) {
      this.delGoods = value;
    },

    //上下架 【在用】
    isPublic(row, index) {
      // if(this.jurisdiction || this.userInfo.roleId){
        const data = {
          goodsIds: [row.goodsId],
          isPutOn: !row.isPutOn,
        };
        updateOnline(data)
        .then(res=>{
          if(res.code == 200){
            this.$message.success(res.message)
            let item = this.tableData[index]
            item.isPutOn = !item.isPutOn
            this.$set(this.tableData, index, item)
          }else{
            this.$message.error(res.message)
          }
        });
        // this.get();
      // }else{
      //   this.$message({
      //       type: "warning",
      //       message: "暂无权限",
      //     });
      //   return
      // }

    },
    //批量上下架 【在用】
    isPublicAll( isPutOn) {
        if(this.delGoods.length === 0) {
          this.$message({
            message: "请选择商品",
            type: "warning",
            center: true,
          });
          return
        }
      // if(this.jurisdiction || this.userInfo.roleId){
        const data = {
          goodsIds: this.delGoods? this.delGoods.map(item => item.goodsId) : [],
          isPutOn: isPutOn,
        };
        updateOnline(data)
        .then(res=>{
          if(res.code == 200){
            this.$message.success(res.message)
            this.get()
            this.delGoods = [];
          }else{
            this.$message.error(res.message)
          }
        });
        // this.get();
      // }else{
      //   this.$message({
      //       type: "warning",
      //       message: "暂无权限",
      //     });
      //   return
      // }
    },


    //批量删除 
    delAll() {
      if(this.delGoods.length === 0) {
        this.$message({
          message: "请选择商品",
          type: "warning",
          center: true,
        });
        return
      }
      let this_ = this
      this.$confirm('确定要删除当前勾选商品吗?', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
      }).then(function () {
          delGoods({ goodsIds: this_.delGoods ? this_.delGoods.map(item => item.goodsId) : []}).then(() => {
            this_.$message({
              message: "操作成功",
              type: "success",
              center: true,
            });
            this_.get();
            this_.delGoods = [];
          })
          .catch(() => {
            this_.$message({
              message: "操作失败",
              type: "warning",
              center: true,
            });
          });
      })
    },

    //获取商品
    get() {
      console.log('执行')
      let param = {
        goodName: this.filterName,
        goodsTypeId: this.goodstypeId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      httpUtil.get('/goods/goodList',param).then(res=>{
         console.log('商品列表',res.data.list)
         this.tableData=res.data.list
         this.total = res.data.total
      })
      // this.loading = true;
      // const { data } = await getGoodsInfo(param); //商品列表
      // if (data) {
      //  let val =  data.pageInfo;
      //   console.log(val)
      //   this.tableData = val.list
      //   this.total = val.total
      //   for (let i = 0; i < this.tableData.length; i++) {
      //     if (this.tableData[i].goodPhoto) {
      //       this.tableData[i].maxPrice  =  (this.tableData[i].maxPrice / 100 ).toFixed(2)
      //       this.tableData[i].goodPhoto = this.tableData[i].goodPhoto.split(
      //         ","
      //       )[0];
      //     }
      //   }
      //   this.tableDataF = this.tableData;
      //   this.loading = false;
      // } else {
      //   this.tableData = [];
      //    this.loading = false;
      // }
    },

    //搜索类名
    typeName(value) {
      this.goodstypeId = value

    },

    //搜索上架
    putaway(value) {
      if (value == 0) {
        this.tableData = this.tableDataF.filter((item) => item.isDistribution);
      } else {
        this.tableData = this.tableDataF.filter((item) => !item.isDistribution);
      }
    },

    //搜索分销
    agency(value) {
      if (value == 0) {
        this.tableData = this.tableDataF.filter((item) => item.isPublic);
      } else {
        this.tableData = this.tableDataF.filter((item) => !item.isPublic);
      }
    },
    //商品管理
    getRedactOrAdd(row) {
        if (row) {
          this.$router.push({
            path: "/good/redactOrAdd",
            query: {
              id: row.goodsId,
            },
          });
        } else {
          this.$router.push({
            path: "/good/redactOrAdd",
          });
        }
    },
    //输入框搜索
    async filterNames() {
      this.get();
    },
    //删除弹窗
    del(row) {
      // if(this.jurisdiction || this.userInfo.roleId){
        delGoods({ goodsIds: [row.goodsId] })
        .then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
            center: true,
          });
          this.get();
        })
        .catch(() => {
          this.$message({
            message: "操作失败",
            type: "warning",
            center: true,
          });
        });
      // }else{
      //   this.$message({
      //       type: "warning",
      //       message: "暂无权限",
      //     });
      //   return
      // }
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.get();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.get();
    },
    // 复制链接
    copyLink(data) {
        this.publicLink = data // data.customPageId
        var clipboard = new Clipboard('.tag-read')
        clipboard.on('success', e => {
            console.log('复制成功')
            //  释放内存

            // clipboard.destory()
        })
        clipboard.on('error', e => {
            // 不支持复制
            console.log('该浏览器不支持复制')
            // 释放内存
            // clipboard.listener.destory()
        })
    },
    async saveSort(row){
		console.log(row)
      if(row && row.sort != ""){
		const pageData = row;

		if(pageData.isDistribution) {
			pageData.isDistribution = 1
		}else {
			pageData.isDistribution = 0
		}
		if(pageData.isKill) {
			pageData.isKill = 1
		}else {
			pageData.isKill = 0
		}
		console.log(pageData)
        const result = await updateGoodsSort(pageData);
        console.log("result:",result)
        this.$message({
          type: "info",
          message: "保存成功",
        });
		this.get()
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },

    // 搜索热词相关
    closeDialog() {
      this.dialogVisible = false
    },
    openDialog() {
      this.getWordsList()
      this.dialogVisible = true
    },
    // 获取搜索热词 (回显)
    getWordsList() {
      let v = this
      let params = {
        pageNum: 1,
        pageSize: 1000,
        cardId: JSON.parse(localStorage.getItem('info')).cardId
      }
      fetchHotWordList(params)
      .then(res=>{
        if(res.data.list && res.data.list.length) {
          v.wordsForm.wordsList = res.data.list.map(item => {
            item.key = item.createTime
            item.value = item.content
            return item
          })
        }
      })
    },
    /***搜索热词提交表单 */
    submitwordsForm(formName) {
      let this_=this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let params = {
          //   content: this_.wordsForm.wordsList.map(item =>  item.value )
          // }
        //  console.log('params', params)
          fetchAddHotWord({
            addSearchRecordDto: this_.wordsForm.wordsList.map(item =>  item.value )
          }).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.closeDialog(0)
            }else {
              this.$message({
                type: "error",
                message: "操作失败，请重试",
              });
            }
          })


          // let data = this.wordsForm.wordsList.map(item => item.value)
          //  let  addCommonDto = {
          //           list: data
          //       }
          // fetchAddCommon(addCommonDto)
          // .then(res=>{
          //   if(res.code == 200){
          //     this.$message({
          //       type:'success',
          //       message: "保存成功",
          //     })
          //   }

          // })
          // .catch(err=>{
          //   console.log(err)
          // })
        }
      })
    },
    // 添加一条搜索热词
    adddWordsItem() {
      this.wordsForm.wordsList.push({
        value: '',
        key: Date.now()
      })
    },
    // 删除一条搜索热词
    removeWordsItem(index) {
        if (index !== -1) {
          this.wordsForm.wordsList.splice(index, 1)
        }
    },
  },
  //生命周期
  async created() {
    this.get();
    //类型筛选
    const datas = await getGoodsTypeInfo({}); //获取分类数据
    Object.assign(this.options, datas.data);
    this.options.unshift({ name: "全部" });
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
};
</script>

<style lang="scss" scoped></style>
